<template>
<div class="container">
    <div class="mb-3">
        <div style="float: left">
            <a href="javascript:void(0)" @click="$router.push({ path: '/home', query: { profileId: profileId }})"><i class="fa fa-fw fa-arrow-left"></i></a>
        </div>
        <div style="float: right">
            <a href="javascript:void(0)" @click="issueCommand('LOCK')"><i class="fa fa-lock text-success" title="Lock Screen"></i></a>&nbsp;&nbsp;
            <a href="javascript:void(0)" @click="issueCommand('REBOOT')"><i class="fa fa-power-off text-prmary" title="Reboot"></i></a>&nbsp;&nbsp;
            <a href="javascript:void(0)" @click="deleteDevice()"><i class="fa fa-trash text-danger" title="Delete Device"></i></a>&nbsp;&nbsp;
            <a href="javascript:void(0)" @click="issueCommand('START_LOST_MODE')"><i class="fa fa-stop text-warning" title="Start Lost Mode"></i></a>&nbsp;&nbsp;
            <a href="javascript:void(0)" @click="issueCommand('STOP_LOST_MODE')"><i class="fa fa-stop-circle-o text-success" title="Stop Lost Mode"></i></a>
        </div>
        <div style="margin: 0 auto; width: 100px;"><h5><b>Device</b></h5></div>
    </div><hr>
    <div class="row">
        <div class="col-md-12 col-xl-12"> 
            <div class="device-blocks">
                <table class="center">
                    <tr>
                        <th class="small text-muted pr-2" scope="row">Serail Number:</th>
                        <td>{{ device_detail.serialNumber }}</td>
                    </tr>
                    <tr v-if="lastLocation">
                        <th class="small text-muted pr-2" scope="row">Location:</th>
                        <td><a :href="'http://maps.google.com/maps?q='+lastLocation.latitude+','+lastLocation.longitude"><i class="fa fa-map-marker" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                        <th class="small text-muted pr-2" scope="row">Battery Level:</th>
                        <td>{{ batteryInfo.batteryLevel }}</td>
                    </tr>
                    <tr>
                        <th class="small text-muted pr-2" scope="row">Battery Checked:</th>
                        <td>{{ humanReadableDateFormat(batteryInfo.batteryLevelTimeCreation) }}</td>
                    </tr>
                    <tr>
                        <th class="small text-muted pr-2" scope="row">Enrollment:</th>
                        <td>{{ humanReadableDateFormat(device_detail.enrollmentTime) }}</td>
                    </tr>
                    <tr>
                        <th class="small text-muted pr-2" scope="row">Last Status Report:</th>
                        <td>{{ humanReadableDateFormat(device_detail.lastStatusReportTime) }}</td>
                    </tr>
                    <tr>
                        <th class="small text-muted pr-2" scope="row">Applied State:</th>
                        <td>{{ device_detail.appliedState }}</td>
                    </tr>
                </table>
            </div>
            <div class="device-blocks" v-if="device_detail.hardwareInfo">
                <table class="center">
                    <tr>
                        <th class="small text-muted pr-2" scope="row">Brand:</th>
                        <td>{{ device_detail.hardwareInfo.brand }}</td>
                    </tr>
                    <tr>
                        <th class="small text-muted pr-2" scope="row">Hardware:</th>
                        <td>{{ device_detail.hardwareInfo.hardware }}</td>
                    </tr>
                    <tr>
                        <th class="small text-muted pr-2" scope="row">Manufacturer:</th>
                        <td>{{ device_detail.hardwareInfo.manufacturer }}</td>
                    </tr>
                    <tr>
                        <th class="small text-muted pr-2" scope="row">Model:</th>
                        <td>{{ device_detail.hardwareInfo.model }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    
</div>
</template>

<script>
import ProfileService from "../services/ProfileService";
export default {
  name: 'Device Detail',
  data() {
    return {
        isDisabled: true,
        profileId: this.$route.query.profileId,
        id: this.$route.query.id,
        device_detail: {},
        batteryInfoArray: [],
        lastLocation: null
    }
  },
  computed: {
    softwareInfo: function() {
      return {
        androidVersion: (this.device_detail.json_response) ? this.device_detail.json_response.softwareInfo.androidVersion : "-",
        androidBuildNumber: (this.device_detail.json_response.softwareInfo) ? this.device_detail.json_response.softwareInfo.androidBuildNumber : "-",
        deviceKernelVersion: (this.device_detail.json_response.softwareInfo) ? this.device_detail.json_response.softwareInfo.deviceKernelVersion : "-",
        bootloaderVersion: (this.device_detail.json_response.softwareInfo) ? this.device_detail.json_response.softwareInfo.bootloaderVersion : "-",
        primaryLanguageCode: (this.device_detail.json_response.softwareInfo) ? this.device_detail.json_response.softwareInfo.primaryLanguageCode : "-",
      }
    },
    networkInfo: function() {
      return {
        imei: (this.device_detail.json_response.networkInfo) ? this.device_detail.json_response.networkInfo.imei : "-",
        wifiMacAddress: (this.device_detail.json_response.networkInfo) ? this.device_detail.json_response.networkInfo.wifiMacAddress : "-",
        networkOperatorName: (this.device_detail.json_response.networkInfo) ? this.device_detail.json_response.networkInfo.networkOperatorName : "-",
        telephonyInfos: (this.device_detail.json_response.networkInfo.hasOwnProperty("telephonyInfos")) ? this.device_detail.json_response.networkInfo.telephonyInfos : null,
      }
    },
    batteryInfo: function() {
      return {
        batteryLevel: (this.batteryInfoArray.length > 0) ? this.batteryInfoArray[this.batteryInfoArray.length-1].batteryLevel : "-",
        batteryLevelTimeCreation: (this.batteryInfoArray.length > 0) ? this.batteryInfoArray[this.batteryInfoArray.length-1].createTime : "-",
        batteryEvent: (this.batteryInfoArray.length > 0) ? this.batteryInfoArray[this.batteryInfoArray.length-1].eventType : "-",
      }
    },
  },
  methods: {
    getDeviceDetail() {
      let data = {
        id: this.id,
      }
      ProfileService.getDeviceDetail(data)
        .then((response) => {
          this.device_detail = response.data.data;
          this.lastLocation = response.data.lastDeviceLocation;
          this.batteryInfoArray = this.device_detail.json_response.powerManagementEvents.filter(i => i.eventType == "BATTERY_LEVEL_COLLECTED");
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async issueCommand(command) {
      let data = {
        "type": command,
        "name": this.device_detail.name,
      } 
      await ProfileService.issueCommand(data)
        .then((response) => {
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    humanReadableDateFormat(date, onlyDate = false) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
        "July", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];
        let dt = new Date(date);
        if (dt == "Invalid Date") {
            return "-";
        }
        let day = ("0" + dt.getDate()).slice(-2);
        let month = dt.getMonth();
        let year = dt.getFullYear();
        let hour = ("0" + dt.getHours()).slice(-2);
        let minute = ("0" + dt.getMinutes()).slice(-2);
        let seconds = ("0" + dt.getSeconds()).slice(-2);
        if (onlyDate) {
            return day + " " + monthNames[month] + " " + year;
        } else {
            return day + " " + monthNames[month] + " " + year + " " + hour + ":" + minute + ":" + seconds;
        }
    },
    deleteDevice() {
      let result = confirm("Are you sure you wont to delete this device?");
      if (result === true) {
        var data = {
          "name": this.device_detail.name,
        } 
        ProfileService.deleteDevice(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
      }
    },
  },
  beforeMount() {
    this.getDeviceDetail();
  },
}
</script>
