<template>
    <div>
        <div class="main-container">
            <KidHeader :profileId="profileId" :serialNumber="serialNumber" />
            <div class="profile-section" style="border-bottom: unset;">
                <div>
                    <span class="pl-3 header-font"><b>Apps</b></span>
                    <div class="float-right pr-3">
                        <span v-if="deviceDetail && deviceDetail.profile_id">{{ deviceDetail.profile_id.profile_name }}</span><br>
                        <small class="device-name" v-if="deviceDetail">{{ deviceDetail.hardwareInfo.brand }} {{ deviceDetail.hardwareInfo.model }}</small>
                    </div>
                </div>
            </div>
            <div class="profile-section" style="border-bottom: unset;">
                <div class="pl-3 pr-3">
                    <div class="input-group mb-3 has-search">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" class="form-control" @keyup.enter="getPlayStore('Terms')" placeholder="Search" v-model="playstoreText">
                        <div class="input-group-prepend">
                            <button class="btn btn-outline-secondary" :disabled="isDisabled" @click="getPlayStore('Terms')"><i class="fas fa-search"></i> <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-section" style="border-bottom: unset;">
                <div class="pl-3 pr-3" :style="{ display: 'block ruby' }">
                    <!--<button type="button" class="btn btn-secondary mr-2" style="background-color: #F2F2F2; color: #333333;" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                      <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" rx="5" fill="#F2F2F2"/>
                        <g clip-path="url(#clip0_4_1125)">
                        <path d="M17.0063 19.9546H23.07" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.8335 19.9546H11.7335" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0061 19.9546C17.0061 20.476 16.8515 20.9857 16.5618 21.4193C16.2721 21.8528 15.8604 22.1907 15.3787 22.3903C14.8969 22.5898 14.3668 22.642 13.8554 22.5403C13.344 22.4386 12.8743 22.1875 12.5056 21.8188C12.1369 21.4501 11.8858 20.9803 11.7841 20.4689C11.6823 19.9575 11.7345 19.4274 11.9341 18.9457C12.1336 18.464 12.4715 18.0522 12.9051 17.7625C13.3386 17.4729 13.8483 17.3182 14.3698 17.3182C15.069 17.3182 15.7395 17.596 16.234 18.0904C16.7284 18.5848 17.0061 19.2554 17.0061 19.9546Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.0608 12.0455H8.8335" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.3333 12.0455C23.3333 11.5241 23.1787 11.0143 22.889 10.5808C22.5993 10.1473 22.1875 9.80934 21.7058 9.6098C21.2241 9.41026 20.694 9.35805 20.1826 9.45978C19.6712 9.5615 19.2014 9.81259 18.8327 10.1813C18.464 10.55 18.2129 11.0198 18.1112 11.5312C18.0095 12.0426 18.0617 12.5726 18.2612 13.0544C18.4608 13.5361 18.7987 13.9479 19.2322 14.2375C19.6658 14.5272 20.1755 14.6818 20.6969 14.6818C21.3961 14.6818 22.0667 14.4041 22.5611 13.9097C23.0555 13.4153 23.3333 12.7447 23.3333 12.0455Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_4_1125">
                        <rect width="16" height="16" fill="white" transform="translate(8 8)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </button>-->
                    <button type="button" class="btn btn-secondary mr-2" :class="{ 'focus': ageFilter }" style="background-color: #F2F2F2; color: #333333;" data-toggle="collapse" data-target="#sortByPopup" aria-controls="sortByPopup" aria-expanded="false" aria-label="Toggle navigation">Age Group
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.33317 3.33331L5.15687 6.59967C5.07019 6.68897 4.92948 6.68897 4.8428 6.59967L1.6665 3.33331" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                    <button type="button" class="btn btn-secondary mr-2" :class="{ 'focus': filterCategory }" style="background-color: #F2F2F2; color: #333333;" data-toggle="collapse" data-target="#filterByCategoryPopup" aria-controls="filterByCategoryPopup" aria-expanded="false" aria-label="Toggle navigation">Category 
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.33317 3.33331L5.15687 6.59967C5.07019 6.68897 4.92948 6.68897 4.8428 6.59967L1.6665 3.33331" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                </div>
                <div class="collapse m-2 footer" id="sortByPopup">
                    <div class="card">
                        <div class="card-body">
                            <p class="text-muted"><b>Choose Age</b> <a href="javascript:void(0)" class="text-black" @click="closePopUp()"><i class="fas fa-times float-right pt-1 pr-2"></i></a></p>
                            <div class="m-2">
                                <div class="row p-2">
                                    <div class="col-xs-3 pr-2">
                                        <div class="btn-group-toggle" data-toggle="buttons">
                                            <label class="btn btn-secondary-custom" :class="{ 'active': ageFilter == 'AGE_RANGE1' }">
                                              <input type="radio"  v-model="ageFilter" :checked="ageFilter == 'AGE_RANGE1'"  value="AGE_RANGE1" >Under 5
                                            </label>
                                          </div>
                                    </div>
                                    <div class="col-xs-3 pr-2">
                                        <div class="btn-group-toggle" data-toggle="buttons">
                                            <label class="btn btn-secondary-custom" :class="{ 'active': ageFilter == 'AGE_RANGE2' }">
                                              <input type="radio" v-model="ageFilter" :checked="ageFilter == 'AGE_RANGE2'" value="AGE_RANGE2" >6-8
                                            </label>
                                          </div>
                                    </div>
                                    <div class="col-xs-3 pr-2">
                                        <div class="btn-group-toggle" data-toggle="buttons">
                                            <label class="btn btn-secondary-custom" :class="{ 'active': ageFilter == 'AGE_RANGE3' }">
                                              <input type="radio" v-model="ageFilter" :checked="ageFilter == 'AGE_RANGE3'" value="AGE_RANGE3" >9 Up
                                            </label>
                                          </div>
                                    </div>
                                </div>
                                <div class="row p-2">
                                  <div class="col-xs-3 pr-2">
                                        <div class="btn-group-toggle" data-toggle="buttons">
                                            <label class="btn btn-secondary-custom" :class="{ 'active': ageFilter == 'AGE_RANGE_ALL' }">
                                              <input type="radio" v-model="ageFilter" :checked="ageFilter == 'AGE_RANGE_ALL'" value="AGE_RANGE_ALL" >All ages up to 12
                                            </label>
                                          </div>
                                  </div>
                                </div>
                                <div class="border-top pt-3">
                                    <a href="javascript:void(0)" @click="clearAgeFilter()">Clear</a>
                                    <button type="button" class="btn btn-primary float-right" @click="getPlayStore('Age')">Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="collapse m-2 footer" id="filterByCategoryPopup">
                    <div class="card">
                        <div class="card-body">
                            <p class="text-muted"><b>Choose Categories</b> <a href="javascript:void(0)" class="text-black" @click="closePopUp()"><i class="fas fa-times float-right pt-1 pr-2"></i></a></p>
                            <div class="m-2" :style="{ 'overflow-y': 'auto', 'height': '450px' }">
                                <table class="table">
                                  <tbody>
                                    <tr v-for="parentCat in playStoreCategories" :key="parentCat">
                                      <td v-for="category in parentCat" :key="category" :style="{'border-top': 'unset', 'padding': '0.25rem'}">
                                        <div class="btn-group-toggle" data-toggle="buttons">
                                            <label class="btn btn-secondary-custom" :class="{ 'active': filterCategory == category }" :style="{ 'font-size': '12px', 'text-transform': 'lowercase', 'width': '100%' }">
                                              <input type="radio"  v-model="filterCategory" :value="category" >
                                              {{ category.replace(/_/g, ' ') }}
                                            </label>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <!--<div class="row p-2" v-for="parentCat in playStoreCategories" :key="parentCat">
                                    <div class="col-xs-6 pr-2" v-for="category in parentCat" :key="category">
                                        <div class="btn-group-toggle ml-4" data-toggle="buttons">
                                            <label class="btn btn-secondary-custom" :style="{ 'font-size': '12px', 'text-transform': 'lowercase' }">
                                              <input type="checkbox" name="filterCategory" v-model="filterCategory" :true-value="category" false-value="" >
                                              {{ category.replace(/_/g, ' ') }}
                                            </label>
                                        </div>
                                    </div>
                                </div>-->
                            </div>
                            <div class="border-top pt-3">
                                <a href="javascript:void(0)" @click="clearCategoryFilter()">Clear</a>
                                <button type="button" class="btn btn-primary float-right" @click="getPlayStore('Category')">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-section" style="border-bottom: unset;">
                <div class="pl-3 pr-3">
                    <div class="nav nav-pills" role="tablist">
                        <a class="nav-link active" href="#playstore" role="tab" data-toggle="tab"><b>All</b></a>
                        <a class="nav-link" href="#installedApps" role="tab" data-toggle="tab"><b>Installed</b></a>
                    </div>
                    <hr :style="{ margin: 'unset' }">
                    <div class="tab-content p-2">
                        <div class="tab-pane active" id="playstore">
                            <h5 class="pb-2"><b>{{ playstoreLabel }}</b></h5>
                            <div class="row justify-content-center" v-if="playStoreApplications.length == 0">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div class="row border-bottom" v-for="application in playStoreApplications" :key="application._id">
                                <div class="col-3 text-center d-flex align-items-center" v-if="!hiddenPackages.includes(application.appId)">
                                    <img :src="application.icon" style="height: 70px; width: 70px;">
                                </div>
                                <div class="col-9 pl-2" v-if="!hiddenPackages.includes(application.appId)">
                                    <span><a href="javascript:void(0)" @click="$router.push({ path: '/application', query: { package_name: application.appId, profile_id: this.profileId, serialNumber: this.serialNumber, playstoreText: this.playstoreText, filterCategory: this.filterCategory, ageFilter: this.ageFilter } })">{{ application.title }}</a></span><br>
                                    <div>
                                        <h2 class="badge badge-light"><i class="fas fa-crown"></i> Silver</h2>
                                        <span class="pl-4"><i class="fas fa-star" style="color: gold;"></i> {{ application.scoreText }}</span>
                                        <span class="pl-4">
                                          <svg width="14" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g clip-path="url(#clip0_552_809)">
                                          <path d="M7 13C10.3136 13 13 10.3136 13 7C13 3.6864 10.3136 1 7 1C3.6864 1 1 3.6864 1 7C1 10.3136 3.6864 13 7 13Z" fill="#00C52B" fill-opacity="0.1" stroke="#00C52B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M4.9998 5.60001C4.94676 5.60001 4.89589 5.57894 4.85838 5.54143C4.82088 5.50393 4.7998 5.45306 4.7998 5.40001C4.7998 5.34697 4.82088 5.2961 4.85838 5.25859C4.89589 5.22108 4.94676 5.20001 4.9998 5.20001" fill="#00C52B" fill-opacity="0.1"/>
                                          <path d="M4.9998 5.60001C4.94676 5.60001 4.89589 5.57894 4.85838 5.54143C4.82088 5.50393 4.7998 5.45306 4.7998 5.40001C4.7998 5.34697 4.82088 5.2961 4.85838 5.25859C4.89589 5.22108 4.94676 5.20001 4.9998 5.20001" stroke="#00C52B" stroke-width="1.25"/>
                                          <path d="M5 5.60001C5.05304 5.60001 5.10391 5.57894 5.14142 5.54143C5.17893 5.50393 5.2 5.45306 5.2 5.40001C5.2 5.34697 5.17893 5.2961 5.14142 5.25859C5.10391 5.22108 5.05304 5.20001 5 5.20001" fill="#00C52B" fill-opacity="0.1"/>
                                          <path d="M5 5.60001C5.05304 5.60001 5.10391 5.57894 5.14142 5.54143C5.17893 5.50393 5.2 5.45306 5.2 5.40001C5.2 5.34697 5.17893 5.2961 5.14142 5.25859C5.10391 5.22108 5.05304 5.20001 5 5.20001" stroke="#00C52B" stroke-width="1.25"/>
                                          <path d="M8.9998 5.60001C8.94676 5.60001 8.89589 5.57894 8.85838 5.54143C8.82088 5.50393 8.7998 5.45306 8.7998 5.40001C8.7998 5.34697 8.82088 5.2961 8.85838 5.25859C8.89589 5.22108 8.94676 5.20001 8.9998 5.20001" fill="#00C52B" fill-opacity="0.1"/>
                                          <path d="M8.9998 5.60001C8.94676 5.60001 8.89589 5.57894 8.85838 5.54143C8.82088 5.50393 8.7998 5.45306 8.7998 5.40001C8.7998 5.34697 8.82088 5.2961 8.85838 5.25859C8.89589 5.22108 8.94676 5.20001 8.9998 5.20001" stroke="#00C52B" stroke-width="1.25"/>
                                          <path d="M9 5.60001C9.05304 5.60001 9.10391 5.57894 9.14142 5.54143C9.17893 5.50393 9.2 5.45306 9.2 5.40001C9.2 5.34697 9.17893 5.2961 9.14142 5.25859C9.10391 5.22108 9.05304 5.20001 9 5.20001" fill="#00C52B" fill-opacity="0.1"/>
                                          <path d="M9 5.60001C9.05304 5.60001 9.10391 5.57894 9.14142 5.54143C9.17893 5.50393 9.2 5.45306 9.2 5.40001C9.2 5.34697 9.17893 5.2961 9.14142 5.25859C9.10391 5.22108 9.05304 5.20001 9 5.20001" stroke="#00C52B" stroke-width="1.25"/>
                                          <path d="M10.2258 8.59998C9.92775 9.20102 9.46775 9.70686 8.89765 10.0605C8.32755 10.4141 7.67001 10.6015 6.99913 10.6015C6.32825 10.6015 5.6707 10.4141 5.1006 10.0605C4.5305 9.70686 4.07051 9.20102 3.77246 8.59998" stroke="#00C52B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                          </g>
                                          <defs>
                                          <clipPath id="clip0_552_809">
                                          <rect width="14" height="14" fill="white"/>
                                          </clipPath>
                                          </defs>
                                          </svg>
                                          {{ application.overallRating }}
                                        </span>
                                        <span class="pl-4" v-if="application.price > 0"><i class="fas fa-dollar-sign"></i> {{ application.price }}</span>
                                    </div>
                                    <div>
                                        <button type="button" :disabled="isDisabled" class="float-right mb-2 install-app-btn" v-if="application.isAppInstalled == false" @click="createProfileApplication(application)">Install</button>
                                        <a :disabled="isDisabled" class="float-right mb-2" v-else @click="deleteProfileApplication(application.appId)" href="javascript:void(0)">
                                          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="32" height="32" rx="16" fill="#F2F2F2"/>
                                            <g clip-path="url(#clip0_4_1201)">
                                            <path d="M20.308 23.3849H11.6921C11.5305 23.3849 11.3705 23.3531 11.2212 23.2912C11.0719 23.2294 10.9362 23.1387 10.822 23.0244C10.7077 22.9101 10.6171 22.7743 10.5554 22.625C10.4936 22.4757 10.4618 22.3156 10.4619 22.154V11.077H21.5382V22.154C21.5382 22.4803 21.4086 22.7932 21.1779 23.0239C20.9471 23.2546 20.6342 23.3849 20.308 23.3849Z" fill="#FCD9E2" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M14.1538 19.6921V14.7698" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.8462 19.6921V14.7698" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M8 11.077H24" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.8466 8.61511H14.1545C13.9929 8.61511 13.8329 8.64696 13.6836 8.70883C13.5343 8.77069 13.3986 8.86138 13.2844 8.97569C13.1701 9.09 13.0795 9.2257 13.0178 9.37504C12.956 9.52438 12.9242 9.68443 12.9243 9.84605V11.0763H19.0776V9.84605C19.0776 9.51977 18.9479 9.20686 18.7172 8.97615C18.4865 8.74544 18.1736 8.61582 17.8473 8.61582L17.8466 8.61511Z" fill="#FCD9E2" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_4_1201">
                                            <rect width="18" height="18" fill="white" transform="translate(7 7)"/>
                                            </clipPath>
                                            </defs>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="installedApps">
                            <div class="row border-bottom" v-for="application in installedApplicationList" :key="application._id">
                                <div class="col-3 text-center d-flex align-items-center">
                                    <img :src="application.app_icon" style="height: 70px; width: 70px;">
                                </div>
                                <div class="col-9 pl-2">
                                    <span><a href="javascript:void(0)" @click="$router.push({ path: '/application', query: { package_name: application.package_name, profile_id: this.profileId, serialNumber: this.serialNumber, playstoreText: this.playstoreText, filterCategory: this.filterCategory, ageFilter: this.ageFilter } })">{{ application.display_text }}</a></span><br>
                                    <div>
                                        <h2 class="badge badge-light"><i class="fas fa-crown"></i> Silver</h2>
                                        <span class="pl-4" v-if="application.playstoreDetail"><i class="fas fa-star" style="color: gold;"></i> {{ application.playstoreDetail.scoreText }}</span>
                                        <span class="pl-4">
                                          <svg width="14" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g clip-path="url(#clip0_552_809)">
                                          <path d="M7 13C10.3136 13 13 10.3136 13 7C13 3.6864 10.3136 1 7 1C3.6864 1 1 3.6864 1 7C1 10.3136 3.6864 13 7 13Z" fill="#00C52B" fill-opacity="0.1" stroke="#00C52B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M4.9998 5.60001C4.94676 5.60001 4.89589 5.57894 4.85838 5.54143C4.82088 5.50393 4.7998 5.45306 4.7998 5.40001C4.7998 5.34697 4.82088 5.2961 4.85838 5.25859C4.89589 5.22108 4.94676 5.20001 4.9998 5.20001" fill="#00C52B" fill-opacity="0.1"/>
                                          <path d="M4.9998 5.60001C4.94676 5.60001 4.89589 5.57894 4.85838 5.54143C4.82088 5.50393 4.7998 5.45306 4.7998 5.40001C4.7998 5.34697 4.82088 5.2961 4.85838 5.25859C4.89589 5.22108 4.94676 5.20001 4.9998 5.20001" stroke="#00C52B" stroke-width="1.25"/>
                                          <path d="M5 5.60001C5.05304 5.60001 5.10391 5.57894 5.14142 5.54143C5.17893 5.50393 5.2 5.45306 5.2 5.40001C5.2 5.34697 5.17893 5.2961 5.14142 5.25859C5.10391 5.22108 5.05304 5.20001 5 5.20001" fill="#00C52B" fill-opacity="0.1"/>
                                          <path d="M5 5.60001C5.05304 5.60001 5.10391 5.57894 5.14142 5.54143C5.17893 5.50393 5.2 5.45306 5.2 5.40001C5.2 5.34697 5.17893 5.2961 5.14142 5.25859C5.10391 5.22108 5.05304 5.20001 5 5.20001" stroke="#00C52B" stroke-width="1.25"/>
                                          <path d="M8.9998 5.60001C8.94676 5.60001 8.89589 5.57894 8.85838 5.54143C8.82088 5.50393 8.7998 5.45306 8.7998 5.40001C8.7998 5.34697 8.82088 5.2961 8.85838 5.25859C8.89589 5.22108 8.94676 5.20001 8.9998 5.20001" fill="#00C52B" fill-opacity="0.1"/>
                                          <path d="M8.9998 5.60001C8.94676 5.60001 8.89589 5.57894 8.85838 5.54143C8.82088 5.50393 8.7998 5.45306 8.7998 5.40001C8.7998 5.34697 8.82088 5.2961 8.85838 5.25859C8.89589 5.22108 8.94676 5.20001 8.9998 5.20001" stroke="#00C52B" stroke-width="1.25"/>
                                          <path d="M9 5.60001C9.05304 5.60001 9.10391 5.57894 9.14142 5.54143C9.17893 5.50393 9.2 5.45306 9.2 5.40001C9.2 5.34697 9.17893 5.2961 9.14142 5.25859C9.10391 5.22108 9.05304 5.20001 9 5.20001" fill="#00C52B" fill-opacity="0.1"/>
                                          <path d="M9 5.60001C9.05304 5.60001 9.10391 5.57894 9.14142 5.54143C9.17893 5.50393 9.2 5.45306 9.2 5.40001C9.2 5.34697 9.17893 5.2961 9.14142 5.25859C9.10391 5.22108 9.05304 5.20001 9 5.20001" stroke="#00C52B" stroke-width="1.25"/>
                                          <path d="M10.2258 8.59998C9.92775 9.20102 9.46775 9.70686 8.89765 10.0605C8.32755 10.4141 7.67001 10.6015 6.99913 10.6015C6.32825 10.6015 5.6707 10.4141 5.1006 10.0605C4.5305 9.70686 4.07051 9.20102 3.77246 8.59998" stroke="#00C52B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                          </g>
                                          <defs>
                                          <clipPath id="clip0_552_809">
                                          <rect width="14" height="14" fill="white"/>
                                          </clipPath>
                                          </defs>
                                          </svg>
                                          {{ application.overallRating }}
                                        </span>
                                    </div>
                                    <div>
                                        <span v-if="application.playstoreDetail && application.playstoreDetail.offersIAP == true">In-App Purchases</span>
                                        <a v-if="application.package_name != 'com.nova.kidsfirstphone'" class="float-right mb-2" @click="deleteProfileApplication(application.package_name)" href="javascript:void(0)">
                                          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="32" height="32" rx="16" fill="#F2F2F2"/>
                                            <g clip-path="url(#clip0_4_1201)">
                                            <path d="M20.308 23.3849H11.6921C11.5305 23.3849 11.3705 23.3531 11.2212 23.2912C11.0719 23.2294 10.9362 23.1387 10.822 23.0244C10.7077 22.9101 10.6171 22.7743 10.5554 22.625C10.4936 22.4757 10.4618 22.3156 10.4619 22.154V11.077H21.5382V22.154C21.5382 22.4803 21.4086 22.7932 21.1779 23.0239C20.9471 23.2546 20.6342 23.3849 20.308 23.3849Z" fill="#FCD9E2" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M14.1538 19.6921V14.7698" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.8462 19.6921V14.7698" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M8 11.077H24" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.8466 8.61511H14.1545C13.9929 8.61511 13.8329 8.64696 13.6836 8.70883C13.5343 8.77069 13.3986 8.86138 13.2844 8.97569C13.1701 9.09 13.0795 9.2257 13.0178 9.37504C12.956 9.52438 12.9242 9.68443 12.9243 9.84605V11.0763H19.0776V9.84605C19.0776 9.51977 18.9479 9.20686 18.7172 8.97615C18.4865 8.74544 18.1736 8.61582 17.8473 8.61582L17.8466 8.61511Z" fill="#FCD9E2" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_4_1201">
                                            <rect width="18" height="18" fill="white" transform="translate(7 7)"/>
                                            </clipPath>
                                            </defs>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-section text-center pt-2">
                  Last Update: {{ lastUpdated }}
            </div>
            <KidFooter />
        </div>
    </div>
</template>
<script>
import KidHeader from '@/components/KidHeader.vue'
import ProfileService from "../../services/ProfileService";
import KidsService from "../../services/KidsService";
import KidFooter from '@/components/KidFooter.vue'
let options = { weekday: 'short', month: 'long', day: 'numeric' };

export default {
  name: 'Play Store',
  components: {
    KidHeader,
    KidFooter
  },
  data() {
    return {
        isDisabled: false,
        installedApplicationList: [],
        profileId: this.$route.query.profileId,
        serialNumber: this.$route.query.serialNumber,
        playstoreText: this.$route.query.playstoreText,
        playStoreApplications: [],
        deviceDetail: null,
        ageFilter: this.$route.query.ageFilter,
        filterCategory:this.$route.query.filterCategory,
        playStoreCategories: [],
        playstoreLabel:"Core APPs Recommended",
        lastUpdated: "No data found.",
        hiddenPackages: ['com.nova.kidsfirstphone','com.facebook.katana','com.twitter.android','com.instagram.android','com.linkedin.android','com.zhiliaoapp.musically','com.pinterest','com.snapchat.android','com.whatsapp','com.instagram.barcelona','com.google.android.youtube']
    }
  },
  methods: {
    getDeviceProfileApplicationList() {
      let data = {
        profile_id: this.profileId,
        install_type: "FORCE_INSTALLED"
      }
      ProfileService.getDeviceProfileApplicationList(data)
        .then((response) => {
          this.installedApplicationList = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async getPlayStore(currentFilter = null) {
      $('#sortByPopup').collapse('hide');
      $('#filterByCategoryPopup').collapse('hide');
        if (currentFilter == "Terms") {
          this.ageFilter = "";
          this.filterCategory = "";
        } else if (currentFilter == "Age") {
          this.filterCategory = "";
          this.playstoreText = "";
        } else if (currentFilter == "Category") {
          this.playstoreText = "";
          this.ageFilter = "";
        }
        this.isDisabled = true;
        let data = {
            profileId: this.profileId,
            serialNumber: this.serialNumber,
            playstoreText: this.playstoreText,
            ageFilter: this.ageFilter,
            filterCategory: this.filterCategory
        }
        if(this.ageFilter) {
            if (this.ageFilter == "AGE_RANGE1") {
              this.playstoreLabel = "Show result for Ages up to 5";
            } else if (this.ageFilter == "AGE_RANGE2") {
              this.playstoreLabel = "Show result for Ages 6-8";
            } else if (this.ageFilter == "AGE_RANGE3") {
              this.playstoreLabel = "Show result for Ages 9-12";
            } else {
              this.playstoreLabel = "Show result for All ages up to 12";
            }
            this.filterCategory = "";
        } else if(this.filterCategory) {
            this.playstoreLabel = "Show result for " + this.filterCategory.replace(/_/g, ' ') + " Category";
        } else if (this.playstoreText) {
          this.playstoreLabel = "Show result for " + this.playstoreText;
        }  else {
          this.playstoreLabel = "Core APPs Recommended"
        } 
        
        
        await KidsService.getPlayStore(data)
            .then((response) => {
                //this.filterCategory = "";
                this.playStoreApplications = [];
                this.isDisabled = false;
                this.playStoreApplications = response.data.data
                this.deviceDetail = response.data.deviceDetail;
                if (this.deviceDetail && this.deviceDetail.lastDeviceActivationDate) {
                    this.lastUpdated = new Date(this.deviceDetail.lastDeviceActivationDate).toLocaleDateString("en-US", options) + " " + new Date(this.deviceDetail.lastDeviceActivationDate).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                } else {
                    this.lastUpdated = "No data found.";
                }
            })
            .catch((e) => {
                this.playStoreApplications = [];
                this.isDisabled = false;
                this.$toast.error(e);
            });
    },
    closePopUp() {
      $('#sortByPopup').collapse('hide');
      $('#filterByCategoryPopup').collapse('hide');
    },
    async clearAgeFilter() {
      this.ageFilter = "";
      await this.getPlayStore();
      $('#sortByPopup').collapse('hide');
    },
    async clearCategoryFilter() {
      this.filterCategory = "";
      await this.getPlayStore();
      $('#filterByCategoryPopup').collapse('hide');
    },
    async createProfileApplication(app) {
        this.isDisabled = true;
        let masterApplicationData = {
            product_id: app.appId,
            package_name: app.appId,
            app_icon: app.icon,
            application_details: app,
            type: "Android"
        };
        await ProfileService.createMasterApplication(masterApplicationData)
        .then((response) => {
        }).catch((e) => {
        })

      let data = {
        profile_id: this.profileId,
        package_name: app.appId,
        is_web_app: false,
        type: "Android",
        serialNumber: this.serialNumber,
      };

      await ProfileService.createProfileApplication(data)
        .then((response) => {
            this.applyPolicy();
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.getPlayStore();
            this.getDeviceProfileApplicationList();
        })
        .catch((e) => {
            this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    deleteProfileApplication(package_name) {
        this.isDisabled = true;
      var data = {
        package_name: package_name,
        profile_id: this.profileId,
        serialNumber: this.serialNumber
      };

      ProfileService.deleteProfileApplication(data)
        .then((response) => {
            this.applyPolicy();
            this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.getPlayStore();
          this.getDeviceProfileApplicationList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    applyPolicy() {
      this.isDisabled = true;
      let data = {
        profile_id: this.profileId,
        serialNumber: this.serialNumber
      };

      ProfileService.applyPolicy(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          //this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  watch: {
  },
  beforeMount() {
    this.getPlayStore();
    this.getDeviceProfileApplicationList();

    let array = [
    'ANDROID_WEAR',       'APPLICATION',        'ART_AND_DESIGN',
    'AUTO_AND_VEHICLES',  'BEAUTY',             'BOOKS_AND_REFERENCE',
    'BUSINESS',           'COMICS',             'COMMUNICATION',
    'DATING',             'EDUCATION',          'ENTERTAINMENT',
    'EVENTS',             'FAMILY',             'FINANCE',
    'FOOD_AND_DRINK',     'GAME',               'GAME_ACTION',
    'GAME_ADVENTURE',     'GAME_ARCADE',        'GAME_BOARD',
    'GAME_CARD',          'GAME_CASINO',        'GAME_CASUAL',
    'GAME_EDUCATIONAL',   'GAME_MUSIC',         'GAME_PUZZLE',
    'GAME_RACING',        'GAME_ROLE_PLAYING',  'GAME_SIMULATION',
    'GAME_SPORTS',        'GAME_STRATEGY',      'GAME_TRIVIA',
    'GAME_WORD',          'HEALTH_AND_FITNESS', 'HOUSE_AND_HOME',
    'LIBRARIES_AND_DEMO', 'LIFESTYLE',          'MAPS_AND_NAVIGATION',
    'MEDICAL',            'MUSIC_AND_AUDIO',    'NEWS_AND_MAGAZINES',
    'PARENTING',          'PERSONALIZATION',    'PHOTOGRAPHY',
    'PRODUCTIVITY',       'SHOPPING',           'SOCIAL',
    'SPORTS',             'TOOLS',              'TRAVEL_AND_LOCAL',
    'VIDEO_PLAYERS',      'WATCH_FACE',         'WEATHER'
  ]
    const chunkSize = 3;
    let finalArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        finalArray.push(chunk)
        // do whatever
    }
    this.playStoreCategories = finalArray
  }, 
}
</script>