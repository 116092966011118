import http from "../http-common";

class KidsService {
    getKidsList(data) {
        return http.get("device/kids-list", { params: data });
    }
    getPlayStore(data) {
        return http.get("application/playstore", { params: data });
    }
    getDeviceProfileMostUsedApplicationList(data) {
        return http.get("device/most-used-apps", { params: data });
    }
    getMostUsedCategory(data) {
        return http.get("application/most-used-category", { params: data });
    }
    getCategoryDetail(data) {
        return http.get("application/category-detail", { params: data });
    }
    updateCategoryDailyLimit(data) {
        return http.post("application/category/limit/update", data);
    }
    getCategoryStackedChart(data) {
        return http.get("application/category-chart", { params: data });
    }
    getCategoryDetailStackedChart(data) {
        return http.get("application/category-detail-chart", { params: data });
    }
    getAppUsageChart(data) {
        return http.get("application/usage-chart", { params: data });
    }
    getDeviceDetailBySerialNumber(data) {
        return http.get("device/by-serialNumber", { params: data });
    }
    submitParentRating(data) {
        return http.post("application/parent/rating", data);
    }

    getNotifications(data) {
        return http.get("notification/kid/list", { params: data });
    }
    unReadNotificationCount(data) {
        return http.get("notification/kid/unread/count", { params: data });
    }
}

export default new KidsService();