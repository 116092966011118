import http from "../http-common";

class ProfileService {
    getDeviceProfileList(data) {
        return http.get("device-profile/list", { params: data });
    }
    getDeviceProfileDetail(data) {
        return http.get("device-profile/detail", { params: data });
    }
    updateProfile(data) {
        return http.post("device-profile/update", data);
    }
    getDeviceProfileApplicationList(data) {
        return http.get("device-profile/application/list", { params: data });
    }
    applyPolicy(data) {
        return http.post("device-profile/apply/policy", data);
    }
    searchPlayStore(data) {
        return http.get("application/playstore/search", { params: data} );
    }
    createProfileApplication(data) {
        return http.post("device-profile/application/create", data);
    }
    createMasterApplication(data) {
        return http.post("application/create/ios", data);
    }
    deleteProfileApplication(data) {
        return http.post("device-profile/application/delete", data);
    }
    toggleProfileAppStatus(data) {
        return http.post("device-profile/application/update/status", data);
    }

    getDeviceList(data) {
        return http.get("device-profile/device/list", { params: data });
    }
    getDeviceDetail(data) {
        return http.get("device/detail", { params: data });
    }
    issueCommand(data) {
        return http.post("device-profile/device/issue/command", data);
    }
    updateScreenTimeSetting(data) {
        return http.post("device-profile/update/screenTime", data);
    }
    deleteDevice(data) {
        return http.post("device-profile/device/delete", data);
    }

    updateProfileSecuritySetting(data) {
        return http.post("device-profile/update/securitySetting", data);
    }

    // Secure Contact
    addContact(data) {
        return http.post("device-profile/contact/add", data);
    }
    listContact(data) {
        return http.get("device-profile/contact/list", { params: data });
    }
    deleteContact(data) {
        return http.delete("device-profile/contact/delete", { params: data });
    }

    //Secure Browser
    addBrowserUrl(data) {
        return http.post("device-profile/browser/add/url", data);
    }
    deleteUrl(data) {
        return http.delete("device-profile/browser/delete/url", { params: data });
    }

    //Wipe
    addWipeFiles(data) {
        return http.post("device-profile/wipe/file/add", data);
    }
    deleteFilePath(data) {
        return http.delete("device-profile/wipe/file/delete", { params: data });
    }
    createProfileWipeApplication(data) {
        return http.post("device-profile/wipe/application/add", data);
    }
    deleteWipeProfileApplication(data) {
        return http.post("device-profile/wipe/application/delete", data);
    }
    updateWipeSetting(data) {
        return http.post("device-profile/wipe/setting", data);
    }

    getApplicationDetail(data) {
        return http.get("device-profile/application/detail", { params: data });
    }
    updateDailyLimit(data) {
        return http.post("device-profile/application/update/daily-limit", data);
    }
}

export default new ProfileService();